@import '../../../../../styles/vars-and-mixins/index.less';

.site-header {
  padding: 0 !important;
  position: fixed;
  z-index: 999;
  width: calc(100% - @sidebar-expanded-width);
  margin-left: @sidebar-expanded-width;
  transition: all @animation-duration-base;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: @header-bg-color;
  border-bottom: 1px solid @rs-primary-bg-color;

  &.collapsed {
    margin-left: @sidebar-collapsed-width;
    width: calc(100% - @sidebar-collapsed-width);
  }

  .header-wrapper {
    height: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    transition: all @animation-duration-base;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .header-wrapper__section-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .header-section-right-button {
    &:hover {
      background: #dee2e6;
      border-color: #dee2e6;
    }
    &:active {
      background: #dee2e6;
    }
    &:focus {
      border-color: #dee2e6;
    }
  }
}

.header-section-right-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 3rem;
  padding-right: 1rem;
}

.market-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 35px;
}

.market-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 42px;
}

@media only screen and (max-width: 768px) {
  .site-header {
    margin-left: 0;
    width: 100%;
  }

  .site-content {
    margin-left: 0;
  }
}
