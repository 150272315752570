@import '../../../styles/vars-and-mixins/index.less';

@toggle-sidebar-icon-size: 30px;
@sidebar-menu-item-height: 40px;
@sidebar-menu-item-color: white;
@sidebar-menu-item-hover-bg-color: @rs-primary-color-dark;
@transition-speed-open: 0.3s;
@transition-speed-close: 0.1s;

.set-transition(@speed) {
  transition: width @speed ease;
}

// used to style the background of the sidebar
.sidebar-background {
  position: fixed;
  left: 0;
  height: 1000px;
  overflow: hidden;
  background-color: @sidebar-bg-color;

  &.open {
    width: 250px;
    .set-transition(@transition-speed-open);
  }

  &.closed {
    width: 80px;
    height: 100%;
    .set-transition(@transition-speed-close);
  }
}

.sidebar-container {
  position: fixed !important;
  height: calc(100vh - 100px);
  overflow: auto;
  left: 0;
  z-index: 2;
  background: @sidebar-bg-color;

  .ant-layout-sider-children {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &.hidden {
    display: none;
  }

  .logo {
    height: 28px;
  }

  .sidebar-icon {
    font-size: 15px;
    color: @sidebar-menu-item-color;
    cursor: pointer;

    &.large {
      font-size: 24px;
    }
  }

  .sidebar-header {
    width: 100%;
    min-height: @layout-header-height;
    height: @layout-header-height;
    display: flex;
    align-items: center;
    background: @rs-primary-color-dark;
    justify-content: center;
    margin-bottom: 16px;
  }

  .ant-menu {
    margin: 0;
    width: 236px;
    list-style-type: none;
    padding: 0;
    background-color: @sidebar-bg-color;

    &.ant-menu-inline-collapsed {
      width: 52px;

      .ant-menu-submenu,
      .ant-menu-submenu-vertical {
        margin: 20px 0;

        .ant-menu-submenu-title {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          margin: 0 !important;
          padding: 0 !important;
          height: 52px !important;
          width: 52px !important;

          .anticon {
            border-radius: 100%;
            margin: 0 !important;
            padding: 0 !important;
          }

          .custom-icon {
            width: 18px;
            height: 18px;
            margin: 0 !important;

            &:hover,
            :active {
              background-color: @sidebar-menu-item-hover-bg-color;
            }
          }
        }
      }

      .anticon {
        margin: 0 !important;
        height: 52px;
        width: 52px;
      }

      .ant-menu-item {
        height: 52px;
        padding: 0 !important;
        justify-content: center;
        margin-top: 10px;
      }

      .ant-menu-title-content {
        display: none !important;
      }
    }

    .ant-menu-submenu-arrow {
      color: @sidebar-menu-item-color;

      &:before,
      &:after,
      &:hover {
        color: @sidebar-menu-item-color;
      }
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      height: @sidebar-menu-item-height;
      line-height: @sidebar-menu-item-height;
      background: @sidebar-bg-color;
      color: @sidebar-menu-item-color;
      font-size: 14px;
      font-weight: 400;
      margin-top: 16px;
      margin-bottom: 0;
      padding-left: 8px !important;
      border-radius: 8px;

      .anticon {
        display: flex;
        flex-direction: row;
        min-height: @sidebar-menu-item-height;
        min-width: @sidebar-menu-item-height;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        color: @sidebar-menu-item-color;
        background-color: transparent;

        &:hover {
          background-color: @sidebar-menu-item-hover-bg-color;
        }
      }

      .submenu-icon,
      .custom-submenu-icon-wrapper {
        display: grid;
        place-content: center;
        height: @sidebar-menu-item-height;
        width: @sidebar-menu-item-height;
        margin-right: 15px;
        border-radius: 50%;
        padding: 15px;

        &:hover {
          background-color: @sidebar-menu-item-hover-bg-color;
        }
      }

      .submenu-icon-svg,
      .custom-submenu-icon-wrapper .custom-icon-svg {
        height: 16px;
        width: 16px;
      }

      a {
        color: @sidebar-menu-item-color;
      }

      &::after {
        border: none;
      }

      .ant-menu-inline {
        width: 100% !important;
      }

      &:hover,
      &.ant-menu-item-selected {
        font-weight: 600;

        .anticon {
          display: flex;
          flex-direction: row;
          min-height: @sidebar-menu-item-height;
          min-width: @sidebar-menu-item-height;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          margin-right: 1rem;
          color: @sidebar-menu-item-color;
        }

        .anticon {
          background-color: @sidebar-menu-item-hover-bg-color;
        }

        a {
          color: @sidebar-menu-item-color;
        }
      }
    }
  }

  .ant-menu-submenu-title {
    .submenu-icon,
    .custom-icon-wrapper {
      display: grid;
      place-content: center;
      height: @sidebar-menu-item-height;
      width: @sidebar-menu-item-height;
      margin-right: 15px;
      border-radius: 50%;

      &:hover {
        background-color: @sidebar-menu-item-hover-bg-color;
      }
    }

    .submenu-icon-svg,
    .custom-icon-svg {
      height: 15px;
      width: 15px;
    }

    &:hover {
      .submenu-icon,
      .custom-icon-wrapper {
        background-color: @sidebar-menu-item-hover-bg-color;
        border-radius: 50%;
      }
    }
  }

  .ant-menu-inline {
    .ant-menu-submenu {
      .ant-menu-item,
      a {
        padding-left: 60px;
        color: @sidebar-menu-item-color;
      }
    }
  }

  .ant-menu-inline,
  .ant-menu-vertical {
    border: none !important;
  }

  .sidebar-footer {
    display: flex;
    width: 222px;
    justify-content: center;
    height: 46px;
  }

  .collapse-action-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    padding: 4px;

    .collapse-icon-wrapper {
      margin-left: 31px;
      margin-right: 14px;
    }
  }
  .ant-layout-sider-trigger {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.custom-submenu-icon-wrapper {
  display: grid;
  place-content: center;
  height: @sidebar-menu-item-height;
  width: @sidebar-menu-item-height;
  margin-right: 15px;
  border-radius: 50%;
  padding: 15px;

  &:hover {
    background-color: @sidebar-menu-item-hover-bg-color;
  }
}

.custom-icon-svg {
  height: 16px;
  width: 16px;

  &.roles-icon {
    height: 24px !important;
    width: 24px !important;
  }
}

.ant-menu-item {
  &:hover,
  &.ant-menu-item-selected {
    .custom-submenu-icon-wrapper {
      background-color: @sidebar-menu-item-hover-bg-color;
      border-radius: 50%;
    }
  }
}
