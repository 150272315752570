@import '../../../styles/vars-and-mixins/index.less';

.spin-icon {
  font-size: 18px;
}

.font-weight-bold {
  font-weight: 600;
}

.margin-right-sm {
  margin-right: 5px;
}

.text-primary {
  color: #197ced;
}

.text-purple {
  color: @rs-primary-color-dark;
}

.line-text {
  line-height: 42px;
}

.unavailable-data-text {
  font-size: 14px;
}

.language-select {
  width: 170px;
}

.real-time-data-title {
  font-weight: 600;
  margin: 16px 0 !important;
  margin-bottom: 5px !important;
}

.ovr-keyword-title,
.data-included-title {
  margin-top: 16px;
  font-size: 1rem !important;
}

.import-cells-button {
  width: 100%;
  margin-bottom: 2rem;
}

.ovr-details-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-form-item-label {
    text-align: left !important;
  }

  .ant-alert-info .ant-alert-icon {
    color: #197ced !important;
    margin-bottom: 90px;
  }
}

.header-actions {
  display: flex;
  gap: 16px;
}

.title-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.add-cells-action-text {
  color: @rs-primary-color;
  cursor: pointer;
  text-decoration: underline;
}

.cell-groups-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ovr-details-card {
  box-shadow: @rs-item-box-shadow;
  border: none;
  height: 100%;

  .ant-typography-copy {
    margin-left: 10px !important;
  }

  .respondant-details {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    width: 100%;

    .date-picker-container {
      align-self: flex-start;
    }

    .export-button-container {
      align-self: flex-start;
    }

    .respondant-stats-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      gap: 1rem;

      .respondant-card {
        flex: 1;
        border-radius: @rs-primary-border-radius;
        height: 131px;
        width: 165px;
        border: @rs-primary-border;
        padding: 1rem;
        max-width: 300px;
        min-width: 150px;
      }

      .title-container {
        min-height: 40px;
      }

      .respondant-title {
        font-size: 1rem;
        font-weight: 700;
        color: @rs-primary-text-color;
        text-align: center;
      }

      .respondant-text {
        font-size: 2.5rem;
        font-weight: 700;
        color: #828282;
        text-align: center;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.model-type-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .model-type-row {
    display: flex;
    gap: 24px;
    align-items: center;
  }
}

.dat-tracking-row {
  display: flex;
  gap: 24px;
  align-items: center;
}

.entry-url-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    align-self: flex-start;
  }
}

.realtime-data-options {
  display: flex;

  .ovr-keyword {
    flex: 1;
    margin-right: 12px;
  }

  .data-included {
    flex: 1.4;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    .ovr-keyword,
    .data-included {
      flex: 0 0 100%;
    }
  }
}

.keyword-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.keyword-item {
  display: flex;
  gap: 8px;
}

.data-included-header {
  display: flex;
  align-items: center;

  .data-included-text {
    margin: 0px;
    margin-left: 5px;
  }
}

.data-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.data-item {
  display: flex;
  gap: 8px;
}

.dat-attention-tracking-button {
  &.ant-switch-checked {
    background-color: @rs-primary-color !important;
  }
}

.redirect-url-input-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tracking-url-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;

  .ant-alert-info .ant-alert-icon {
    color: #197ced !important;
    margin-bottom: 105px;
  }

  .tracking-url-info-alert {
    border-radius: @rs-primary-border-radius;
    padding: 16px;
    background-color: #ddf7ff;
  }

  .ovr-keyword {
    display: flex;
    flex-direction: column;
    background-color: @rs-primary-bg-color;
    padding: 16px;
    border-radius: @rs-primary-border-radius;
    width: 100%;
  }

  .data-included {
    background-color: @rs-primary-bg-color;
    padding: 16px;
    border-radius: @rs-primary-border-radius;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 12px;

    .tracking-url-info-alert,
    .ovr-keyword {
      background-color: @rs-primary-bg-color;
      padding: 16px;
    }
  }
}

.tracking-url-info-alert {
  display: flex;
  flex-direction: row;
  min-height: 140px;
  flex: 0.8;
  border: none;
  padding: 16px;
}

.realtime-data-alert {
  background-color: white;
  border: none;
  margin-top: 16px;

  .ant-alert-info .ant-alert-icon {
    color: #197ced !important;
  }
}

.tracking-legend {
  flex: 0.8;
  padding: 8px 16px;
  padding-bottom: 0;
  background-color: @rs-primary-bg-color;
  border-radius: @rs-primary-border-radius;

  ul {
    margin-top: 8px;
    margin-bottom: 16px;
    padding-left: 20px;

    ul {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  li {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ant-typography {
    word-break: break-all;
  }
}

.realtime-data-alert.ant-alert {
  background-color: white;
  border: none;
  margin-top: 16px;
}
