@import '../../../../../../../styles/vars-and-mixins/index.less';

.loading-more-spin-wrapper {
  text-align: center;
  padding: 24px;
  width: 100%;
}

.product-library-grid-list-wrapper {
  height: 100%;
  display: 'flex';
  flex-direction: 'column';
  gap: 1rem;
  background: @header-bg-color;
  padding: 2rem;
  border-radius: @rs-item-border-radius;
  width: 100%;
  overflow: hidden;
}

.product-library-grid-list {
  background: @header-bg-color;
  max-height: 100%;
}

.product-library-table-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
