.fixture-item-properties-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 24px;
  overflow-y: auto;
  overflow-x: hidden;
}

.fixture-item-detail-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
}

.fixture-display-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.fixture-item-detail-property {
  font-weight: 600;
}
